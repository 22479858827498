import React from 'react';
import { Link as ReactLink } from 'gatsby';
import styled from 'styled-components';
import sortBy from 'lodash/sortBy';
import { Helmet } from 'react-helmet';
import moment from 'moment';
import { include } from 'underscore.string';
import SummarySrc from '../../components/Summary';
import { color } from '../../css/css.settings.color';
import { vw } from '../../css/css.mixins.media';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Template from '../../templates';

const Header = styled.h2`
	color: ${color.textDarkPrimary};
	margin-top: 0;
	margin-bottom: 0;
`;

const Summary = styled(SummarySrc)`
  color: ${color.textDarkSecondary};
  text-decoration: none !important;
`;

const Link = styled(ReactLink)`
  position: relative;
  margin-bottom: 30px;
  padding: 2em;
  margin-left: 1em;
  margin-right: 1em;
  border-radius: 17px;
  max-width: 30rem;

  &:hover,
  &:active,
  &:focus {
    color: ${color.brandPrimary};
    text-decoration: underline;
  }

  &,
  &:active,
  &:focus,
  &:hover {
    text-decoration: none;
  }

  &:after {
    position: absolute;
    border-radius: 17px;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: '';
    transition: opacity 0.18s;
    opacity: 1;
    box-shadow: 0 3px 13px rgba(0,0,0,0.15);
  }

  ${vw.lv8`
    margin-bottom: 30px;
    padding: 2em;
    margin-left: 1em;
    margin-right: 1em;
    max-width: 40rem;

    &:after {
      position: absolute;
      border-radius: 17px;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      content: '';
      transition: opacity 0.18s;
      opacity: 0;
      box-shadow: 0 3px 13px rgba(0,0,0,0.15);
    }

    &:hover:after,
    &:focus:after {
      opacity: 1;
    }

    &:hover ${Header},
    &:active ${Header},
    &:focus ${Header} {
      color: ${color.brandPrimary};
      text-decoration: underline;
    }

    &:hover ${Summary}  {
      color: ${color.textDarkSecondary};
    }
  `}
`;

const DateTime = styled.div`
	color: ${color.textDarkPrimary};
	font-size: 0.9em;
`;

const LinksSection = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
`;

class BlogIndex extends React.Component {
	render() {
		const pageLinks = [];

		// Sort pages.
		const { edges } = this.props.data.allMarkdownRemark;
		const sortedPages = sortBy(edges, (edge) => edge.node.date);
		const { site: { siteMetadata: { blogTitle } } } = this.props.data;

		sortedPages.forEach(({ node: page }) => {
			const path = page.frontmatter.path;
			const title = page.frontmatter.title || path;
			const layout = page.frontmatter.layout;

			if (layout === 'post' && !include(page.path, '/404')) {
				pageLinks.push(
					<Link key={page.path} to={path}>
						<Header>{title}</Header>

						<Summary body={page.excerpt} />

						<DateTime>{moment(page.date).calendar()}</DateTime>
					</Link>
				);
			}
		});

		return (
			<Template {...this.props}>
				<Helmet>
					<title>{blogTitle}</title>
				</Helmet>
				<LinksSection>{pageLinks}</LinksSection>
			</Template>
		);
	}
}

BlogIndex.propTypes = {
	route: PropTypes.object
};

export default BlogIndex;
export const pageQuery = graphql`
	query {
		allMarkdownRemark(
			sort: { order: DESC, fields: [frontmatter___date] }
			filter: { fileAbsolutePath: { regex: "/src/contents/blog/" } }
		) {
			edges {
				node {
					id
					excerpt(pruneLength: 250)
					frontmatter {
						date
						layout
						title
						featureImage
						invertLayout
						path
					}
				}
			}
		}
		site {
			siteMetadata {
				title
				description
				authorName
				subTitle
				authorBio
				gaCode
				blogTitle
			}
		}
	}
`;
