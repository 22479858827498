import React from 'react';
import { prune } from 'underscore.string';
import { fixLinks } from '../utils';
import PropTypes from 'prop-types';

export default class Summary extends React.Component {
	componentDidMount() {
		fixLinks(this.refs.markdown, this.context.router);
	}

	summary(body) {
		const split = body.split('<hr>');
		return split.length !== 0 && split[0].length < 200 ? split[0] : prune(body.replace(/<[^>]*>/g, ''), 200);
	}

	render() {
		return <p ref="markdown" dangerouslySetInnerHTML={{ __html: this.summary(this.props.body) }} {...this.props} />;
	}
}

Summary.propTypes = {
	body: PropTypes.string.isRequired
};

Summary.contextTypes = {
	router: PropTypes.object.isRequired
};
